import React from 'react';
import { SearchBar } from '../../components';
import { Navigate } from "react-router-dom";
import './home.css';

function Home({ search, address, dataLoaded, isLoading, error, connectWallet }) {
    return (
        <div className="Home">
            {dataLoaded && address !== '' ? (
                <Navigate to="/dashboard" />
            ) : (
                <div className="home__container">
                    <div className="home__header">
                        <h1 className="home__header__title">OPTIMUS CALCULATOR</h1>
                    </div>
                    <div className="home__searchBar">
                        <SearchBar search={search} connectWallet={connectWallet} dataLoaded={dataLoaded} address={address} isLoading={isLoading} error={error} />
                    </div>
                    {isLoading && !dataLoaded && address && !error ? <><div className='circle__loading'>
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                        <div className="loading__note">
                            <p>Calculating...</p>
                            <p>This may take longer if you have many transactions of OPT.</p>
                        </div></> : null}
                    {error ? <div className="error__note"><p>Oops! Something went wrong. Please try again.</p></div> : null}
                </div>
            )}
        </div>
    );
}

export default Home;