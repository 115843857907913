import React, { useState } from 'react'
import './searchBar.css'

function SearchBar({ search, connectWallet, dataLoaded, address, isLoading, error }) {
    const [searchValue, setSearchValue] = useState('')

    const submitHandler = e => {
        e.preventDefault();
        search(searchValue);
    }

    return (
        <div className='searchBar__container'>
            <form className='searchBar__form' onSubmit={submitHandler}>
                <div className="connectWallet__component">
                    <input className='searchBar__input' type='text' placeholder='Enter Public Address (0x) or ENS' onChange={e => setSearchValue(e.target.value)} />
                    {isLoading && !dataLoaded && address && !error ? <button className='searchBar__loading'>Loading</button> :
                        <button className='searchBar__button' type='submit'>Calculate</button>}
                    {isLoading && !dataLoaded && address && !error ? null : <><div>OR</div><button className='searchBar__connectWallet__button' onClick={connectWallet}>Connect Wallet</button></>}
                </div>
            </form>
        </div>
    )
}

export default SearchBar