import React, { useEffect, useState } from 'react';
import './dashboard.css';
import RefreshIcon from '@mui/icons-material/Refresh';

function Dashboard({ address, addressData, search, error, isRefreshing }) {
  const [investedOPT, setinvestedOPT] = useState(0);
  const [investedUSD, setinvestedUSD] = useState(0);
  const [ownedOPT, setOwnedOPT] = useState(0);
  const [OPTRate, setOPTRate] = useState(0);
  const [ownedUSD, setOwnedUSD] = useState(0);
  const [avgPrice, setAvgPrice] = useState(0);
  const [totalProfitOPT, setTotalProfitOPT] = useState(0);
  const [totalProfitUSD, setTotalProfitUSD] = useState(0);
  const [totalProfitPercent, setTotalProfitPercent] = useState(0);
  const [forecastValues, setForecastValues] = useState([]);

  if (!addressData && address && !error) {
    search(address);
  }

  useEffect(() => {
    const calcData = () => {
      if (addressData && addressData.length > 0) {
        let investedOPT = 0;
        let investedUSD = 0;
        for (let i = 0; i < addressData[0].length; i++) {
          if (addressData[0][i].ValueUSD !== "-") {
            if (addressData[0][i].Status === 'OUT') {
              investedOPT = investedOPT - parseFloat(addressData[0][i].Quantity)
              investedUSD = investedUSD - parseFloat(addressData[0][i].ValueUSD)
            }
            else if (addressData[0][i].Status === 'IN') {
              investedOPT = investedOPT + parseFloat(addressData[0][i].Quantity)
              investedUSD = investedUSD + parseFloat(addressData[0][i].ValueUSD)
            }
          }
        }
        setinvestedOPT(investedOPT);
        setinvestedUSD(investedUSD);
        setOwnedOPT(addressData[1]);
        setOPTRate(addressData[2]);
        setOwnedUSD(addressData[1] * addressData[2]);
      }
    }
    calcData();
  }, [addressData]);

  useEffect(() => {
    const calcProfit = () => {
      if (addressData && addressData.length > 0) {
        let avgPrice = 0;
        let totalProfitOPT = 0;
        let totalProfitUSD = 0;
        let totalProfitPercent = 0;

        avgPrice = investedUSD / ownedOPT;
        totalProfitOPT = ownedOPT - investedOPT;
        totalProfitUSD = ownedUSD - investedUSD;
        totalProfitPercent = ((ownedUSD - investedUSD) / investedUSD) * 100;

        setAvgPrice(avgPrice);
        setTotalProfitOPT(totalProfitOPT);
        setTotalProfitUSD(totalProfitUSD);
        setTotalProfitPercent(totalProfitPercent);
      }
    }
    const calcForecast = () => {
      let forecastValues = [];
      let days = 1;
      let percent = 1;
      for (let i = 0; i <= 12; i++) {
        var forecastOPT = calculateForcast(ownedOPT, days, percent);
        if (days === 1) {
          forecastValues.push({ days: days, percent: percent, amount: forecastOPT.toFixed(2) });
        }
        else {
          forecastValues.push({ days: days - 1, percent: percent, amount: forecastOPT.toFixed(2) });
        }
        days = days + 30;
      }
      setForecastValues(forecastValues);
    }
    calcProfit();
    calcForecast();
  }, [addressData, investedOPT, investedUSD, ownedOPT, ownedUSD, OPTRate]);

  if (addressData && address) {
    return (
      <div className="dashboard">
        <div className="dashboard__container">
          <div className="dashboard__container__header">
            <h1 className="dashboard__container__header__title">DASHBOARD</h1>
            <h2 className="dashboard__container__header__address">Address : {address.substring(0,5)}...{address.substring(address.length - 4,address.length)}</h2>
            <h2 className="dashboard__container__header__balance">Balance: {ownedOPT.toFixed(2)} OPT (${ownedUSD.toFixed(2)})</h2>
            <h2 className="dashboard__container__header__rate">OPT Rate : ${OPTRate}</h2>
            {isRefreshing ? <RefreshIcon className="dashboard__container__header__refreshing" onClick={() => search(address)} /> : <RefreshIcon className="dashboard__container__header__refresh" onClick={() => search(address)} />}
          </div>


          <div className="dashboard__note">
            <p>Note: The dashboard is still in development.</p>
          </div>

          <div className="dashboard__container__data">
            {investedUSD >= 0 ? <p id='investedUSD'>Total Invested : ${investedUSD.toFixed(2)} </p>: <p id='investedUSD'>Total Invested : $0</p>}
            {investedOPT >= 0 ? <p id='investedOPT'>Total Invested : {investedOPT.toFixed(2)} OPT</p> :<p id='investedOPT'>Total Invested : 0 OPT</p>}
            <p id='avgPrice'>AVG. Price (per OPT) : {avgPrice.toFixed(2)}</p>
            {totalProfitOPT >= 0 ? <p id='totalProfitOPT'>Total Profit : {totalProfitOPT.toFixed(2)} OPT</p> : <p id='totalLossOPT'>Total Loss : {(-1 )* totalProfitOPT.toFixed(2)} OPT</p>  }
            {totalProfitUSD >= 0 ? <p id='totalProfitUSD'>Total Profit : ${totalProfitUSD.toFixed(2)}</p> : <p id='totalLossUSD'>Total Loss : ${(-1) * totalProfitUSD.toFixed(2)}</p>  }
            {totalProfitPercent >= 0 ? <p id='totalProfitPercent'>Total Profit (%): {totalProfitPercent.toFixed(2)}%</p> : <p id='totalLossPercent'>Total Loss (%): {(-1) * totalProfitPercent.toFixed(2)}%</p>  }
          </div>
        </div>

        <div className="dashboard__table__container">
          <div className="dashboard__table">
            <table>
              <caption>Transaction History</caption>
              <thead>
                <tr>
                  <th>Coin</th>
                  <th>Method</th>
                  <th>Quantity</th>
                  <th>ValueUSD</th>
                  <th>Age</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {addressData[0].map((data, index) => (
                  <tr key={index}>
                    <td>{data.Coin}</td>
                    <td>{data.Method}</td>
                    <td>{data.Quantity}</td>
                    <td>{data.ValueUSD}</td>
                    <td>{data.Age}</td>
                    <td>{data.Status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="dashboard__table_forecast">
            <table>
              <caption>Forecasted Values</caption>
              <thead>
                <tr>
                  <th>OPT</th>
                  <th>Days</th>
                </tr>
              </thead>
              <tbody>
                {forecastValues.map((data, index) => (
                  <tr key={index}>
                    <td>{data.amount} OPT</td>
                    <td>{data.days}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    );
  }
  else {
    return (
      <div className='dashboard__container'>
        {!address && !error ? <p>Please provide an address.</p> : null}
        {address && error ? <p>Error Occured Please try again</p> : null}
        {address && !error ? <p>Loading...</p> : null}
      </div>
    );
  }
}

function calculateForcast(opt, days, incPercent) {
  // a= p(1+r)^t
  let amount = 0;
  let percent = incPercent || 1;
  let day = days;
  let rate = 1 + (percent / 100);
  amount = opt * Math.pow(rate, day);
  return amount;
}

export default Dashboard;