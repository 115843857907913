import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Home, Dashboard, NoPage } from './pages';
import axios from 'axios';
const ethers = require("ethers")

function App() {
  const [address, setAddress] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [addressData, setAddressData] = useState();

  const [isLoading, setIsLoading] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [error, setError] = useState(false)

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        if (address === '') {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          const account = accounts[0];
          setAddress(account);
        }
        if(address !== null){
          handleAddressSearch(address);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      window.alert('Please Install MetaMask!');
    }
  };

  if (window.ethereum) {
    window.ethereum.on('accountsChanged', function (accounts) {
      setAddress(accounts[0]);
    });
  }


  const handleAddressSearch = async function (address) {
    setError(false);
    setIsLoading(true);
    setIsRefreshing(true);

    if (address.includes('.eth')) {
      address = await resolveENS(address);
    }

    if (address.substring(0, 2) !== '0x') {
      address = null;
    }

    if(address !== null){
      localStorage.setItem('address', address);
      console.log(address);
      setAddress(address);
      let dataArray = [];
      try {
          const result = await axios.get(`https://optbackend.atit286.repl.co/optCalc?address=${address}`);
          const optBalance = await axios.get(`https://optbackend.atit286.repl.co/optBalance?address=${address}`);
          const optRate = await axios.get(`https://optbackend.atit286.repl.co/optRate`);
          dataArray.push(result.data);
          dataArray.push(optBalance.data);
          dataArray.push(optRate.data);
          console.log(dataArray);
          setAddressData(dataArray);
          setDataLoaded(true);
          setIsLoading(false);
          setIsRefreshing(false);
      }
      catch {
        setError(true);
      }
    }
    else{
      setIsLoading(false);
      setIsRefreshing(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (address === '') {
      const address = localStorage.getItem('address');
      if (address) {
        setAddress(address);
      }
    }
  }, [address]);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home dataLoaded={dataLoaded} address={address} isLoading={isLoading} error={error} search={handleAddressSearch} connectWallet={connectWallet} />} />
        <Route path="/dashboard" element={<Dashboard address={address} addressData={addressData} error={error} isRefreshing={isRefreshing} search={handleAddressSearch} />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

async function resolveENS(address) {
  try{
    var provider = new ethers.providers.JsonRpcProvider("https://eth-mainnet.gateway.pokt.network/v1/5f3453978e354ab992c4da79");
    let resolvedName = await provider.resolveName(address);
    return resolvedName;
  }
  catch{
    return '';
  }
}

export default App;